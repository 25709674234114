import { IconComponent } from 'src/types/icon';

const BustaPaga: IconComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" {...props}>
    <path
      fill={props.fill}
      d="M27.2.1H2.8C1.2.1 0 1.4 0 2.9v24.3C0 28.7 1.3 30 2.8 30h24.5c1.5 0 2.8-1.3 2.8-2.8V2.9C30 1.3 28.7.1 27.2.1zm1 27.1c0 .6-.4 1-1 1H2.8c-.6 0-1-.4-1-1V2.9c0-.6.4-1 1-1h24.5c.6 0 1 .4 1 1l-.1 24.3z"
    />
    <path
      fill={props.fill}
      d="M25.3 13.4c-.1-.4-.4-.7-.9-.7H5.6c-.5 0-.9.4-.9.9v12.3c0 .2.1.3.3.4.2.1.4.2.6.2h18.8c.2 0 .3-.1.5-.2.3-.1.5-.3.5-.5V13.6c-.1 0-.1-.1-.1-.2zm-14.6 5.1h12.9v2.1H10.7v-2.1zm-1.8 2.1H6.5v-2.1h2.3v2.1zm14.6-3.9H10.7v-2.2h12.9v2.2zm-14.6 0H6.5v-2.2h2.3v2.2zm-2.4 5.7h2.3v2.2H6.5v-2.2zm4.2 0h12.9v2.2H10.7v-2.2z"
    />
  </svg>
);

export default BustaPaga;
