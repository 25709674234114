import { IconComponent } from 'src/types/icon';

const CiPaper: IconComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" {...props}>
    <path
      fill={props.fill}
      d="M27.2 4H2.8C1.2 4 0 5.3 0 6.8v16c0 1.5 1.3 2.8 2.8 2.8h24.5c1.5 0 2.8-1.3 2.8-2.8v-16C30 5.2 28.7 4 27.2 4zm1 18.8c0 .6-.4 1-1 1H2.8c-.6 0-1-.4-1-1v-16c0-.6.4-1 1-1h24.5c.6 0 1 .4 1 1l-.1 16z"
    />
    <path
      fill={props.fill}
      d="M24.4 7.8H5.6c-.5 0-.9.4-.9.9s.4.9.9.9h18.8c.5 0 .9-.4.9-.9s-.4-.9-.9-.9zM10.4 20H5.7c-.5 0-.9.4-.9.9s.4.9.9.9h4.7c.5 0 .9-.4.9-.9s-.4-.9-.9-.9z"
    />
  </svg>
);

export default CiPaper;
