import React from 'react';
import { IconComponent } from 'src/types/icon';

const Close: IconComponent = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <g fill="#FF7033">
      <path d="M2.27509 23.98758c-.58841.03461-1.1671-.16328-1.61402-.55194-.88143-.89698-.88143-2.34568 0-3.24266L19.96136.66804c.91675-.86782 2.3553-.81958 3.21314.10784.77572.83867.82094 2.12772.10586 3.01986l-19.414 19.6399c-.44117.38306-1.01057.58057-1.59127.55194Z" />
      <path d="M21.5527 23.98758c-.59635-.00257-1.16793-.24204-1.59134-.6669L.66099 3.79566C-.1556 2.83097-.04458 1.37917.90901.553c.8511-.73733 2.10631-.73733 2.95734 0l19.414 19.52494c.91654.86805.96393 2.32341.10586 3.25061a2.29012 2.29012 0 0 1-.10585.10709c-.47538.4182-1.101.61806-1.72767.55194Z" />
    </g>
  </svg>
);

export default Close;
