import { configureStore } from '@reduxjs/toolkit';
import { batchDispatchMiddleware } from 'redux-batched-actions';
import login from 'src/features/login/slice';
import customerData, {
  businessDataReducer,
} from 'src/features/merchant/borrowerData/slice';
import loanSimulation from 'src/features/merchant/loanSimulation/slice';
import notification from 'src/features/notification/slice';
import agreements from 'src/components/Agreements/slice';
import aml from 'src/features/merchant/aml/slice'
import company from 'src/features/fic/slice'
import guarantor from 'src/features/merchant/borrowerData/slice/guarantor'

const store = configureStore({
  reducer: {
    login,
    notification,
    loanSimulation,
    customerData,
    businessData: businessDataReducer,
    agreements,
    aml,
    company,
    guarantor
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(batchDispatchMiddleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
