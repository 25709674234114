import { IconComponent } from 'src/types/icon';

const Pencil: IconComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" {...props}>
    <g fill="none" fillRule="evenodd">
      <circle cx="15" cy="15" r="14.5" stroke="#000" />
      <path
        fill={props.fill}
        fillRule="nonzero"
        d="M8 19.0444509V22h2.95554912l8.55553688-8.63331452-2.95554908-2.95554912L8 19.0444509Zm13.7666367-7.93331607c.3111104-.31111043.3111104-.77777608 0-1.08888651l-1.788885-1.7889155c-.3111105-.31111043-.7777761-.31111043-1.0888865 0l-1.39999698 1.39999695 2.95554908 2.95554912 1.3222194-1.47774406Z"
      />
    </g>
  </svg>
);

export default Pencil;
