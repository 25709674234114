import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useMerchant from 'src/features/login/hooks/useMerchant';
import {
  RedirectPath,
  actions,
  notificationSelector,
} from 'src/features/notification/slice';
import Paper from '../AppWrapper/components/Paper';
import { PaperStatus } from '../AppWrapper/components/Paper/Paper';
import Button from '../lib/Button';
import FlexContainer from '../lib/FlexContainer';
import styles from './MessagePage.module.scss';

export type MessagePageProps = {
  ctaRedirectPath: RedirectPath;
  status: PaperStatus;
  note?: React.ReactNode;
} & (
  | {
      doubleCta: 'false';
    }
  | {
      doubleCta: 'true';
      ctaTextSecondary: string;
      ctaRedirectPathSecondary: RedirectPath;
    }
);

const MessagePage: React.FC<MessagePageProps> = (props: MessagePageProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { context, status } = useSelector(notificationSelector);
  const { t } = useTranslation('merchant/submit-error');
  const { merchant } = useMerchant();
  const role = merchant?.scope?.[0];

  const handleOnClick = () => {
    dispatch(actions.resetNotification());
    navigate(props.ctaRedirectPath);
  };

  switch (props.doubleCta) {
    case 'true': {
      const handleOnClickSecondary = () => {
        dispatch(actions.resetNotification());
        navigate(props.ctaRedirectPathSecondary);
      };

      return (
        <Paper
          header={
            role
              ? t(`${context}.${status}.header.${role}`)
              : t(`${context}.${status}.header`)
          }
          subheader={
            role
              ? t(`${context}.${status}.subheader.${role}`)
              : t(`${context}.${status}.subheader`)
          }
          status={props.status}
          note={props.note}
          icon={props.status === 'error' ? 'Exclamation' : 'Success'}
        >
          <FlexContainer className={styles.buttons}>
            <Button
              className={styles.button}
              type="submit"
              secondary={true}
              onClick={handleOnClickSecondary}
            >
              {props.ctaTextSecondary}
            </Button>
            <Button
              className={styles.button}
              type="submit"
              primary={true}
              onClick={handleOnClick}
            >
              {context && t(`${context}.${status}.cta`)}
            </Button>
          </FlexContainer>
        </Paper>
      );
    }
    case 'false': {
      return (
        <Paper
          header={
            role && context
              ? t(`${context}.${status}.header.${role}`)
              : context
              ? t(`${context}.${status}.header`)
              : t(`${status}.header`)
          }
          subheader={
            role && context
              ? t(`${context}.${status}.subheader.${role}`)
              : context
              ? t(`${context}.${status}.subheader`)
              : t(`${status}.subheader`)
          }
          status={props.status}
          note={props.note}
          icon={props.status === 'error' ? 'Exclamation' : 'Success'}
        >
          <Button
            className={styles.button}
            type="submit"
            primary={true}
            onClick={handleOnClick}
            icon={'ArrowRight'}
            iconPlacement="right"
          >
            {context ? t(`${context}.${status}.cta`) : t(`${status}.cta`)}
          </Button>
        </Paper>
      );
    }
  }
};

export default MessagePage;
