import { Maybe } from 'true-myth';

const currencyMap: Record<string, string> = {
  EUR: '€',
};

export const getCurrencySymbol = (currency: string): string =>
  Maybe.of(currencyMap[currency]).unwrapOr('€');

const formatter = new Intl.NumberFormat('it-IT', { minimumFractionDigits: 2 });

export const formatMoneyAmount = (value: number): string =>
  formatter.format(value);
