import { Suspense } from 'react';
import Loader from 'src/components/Loader';
import styles from './withSuspense.module.scss';

const withSuspense =
  (Component: React.LazyExoticComponent<React.FC<Record<string, unknown>>>) =>
  () =>
    (
      <Suspense fallback={<Loader className={styles.loader} size={120} />}>
        <Component />
      </Suspense>
    );

export default withSuspense;
