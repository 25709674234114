import React from 'react';
import { IconComponent } from 'src/types/icon';

const Interrogative: IconComponent = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
  <path d="M12 16.8c-.26494 0-.48.21506-.48.48 0 .26494.21506.48.48.48.26494 0 .48-.21506.48-.48 0-.26494-.21506-.48-.48-.48Z"/>
  <circle cx="12" cy="17.28" r="1"/>
  <path d="M12 0C5.3827 0 0 5.3827 0 12s5.3827 12 12 12 12-5.3827 12-12S18.6173 0 12 0Zm0 23.04C5.91263 23.04.96 18.08738.96 12 .96 5.91263 5.91263.96 12 .96 18.08738.96 23.04 5.91263 23.04 12c0 6.08738-4.95262 11.04-11.04 11.04Z"/>
  <path d="M15.3053 8.51423c-.23714-1.35262-1.34785-2.46337-2.70047-2.70145-.99746-.1747-2.00353.09216-2.76478.73247-.76224.6413-1.2 1.57922-1.2 2.5747 0 .26494.21506.48.48.48.26493 0 .48-.21506.48-.48 0-.71137.31298-1.38145.85823-1.83839.55294-.46368 1.25761-.64992 1.98145-.52223.96193.16894 1.752.96 1.92193 1.92192.18239 1.04063-.28993 2.03906-1.20479 2.54208-1.0253.56545-1.63678 1.54176-1.63678 2.69662v.96c0 .26494.21507.48.48.48.26494 0 .48-.21506.47808-.48v-1.04447c0-.71615.42722-1.37854 1.14047-1.77215 1.26038-.6931 1.93814-2.11964 1.68666-3.5491Z"/>
</svg>
);

export default Interrogative;
