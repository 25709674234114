import { IconComponent } from 'src/types/icon';

const ArrowLeft: IconComponent = (props) => (
  <svg
    viewBox="0 0 20 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path fill={props.fill} d="m8 18 1.4-1.4L3.8 11H20V9H3.8l5.6-5.6L8 2l-8 8 8 8z"/>
  </svg>
);

export default ArrowLeft;
