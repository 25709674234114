import { IconComponent } from 'src/types/icon';

const Cud: IconComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" {...props}>
    <path
      fill={props.fill}
      d="M27.2.1H2.8C1.2.1 0 1.4 0 2.9v24.3C0 28.7 1.3 30 2.8 30h24.5c1.5 0 2.8-1.3 2.8-2.8V2.9C30 1.3 28.7.1 27.2.1zm1 27.1c0 .6-.4 1-1 1H2.8c-.6 0-1-.4-1-1V2.9c0-.6.4-1 1-1h24.5c.6 0 1 .4 1 1l-.1 24.3z"
    />
    <path
      fill={props.fill}
      d="m12 10 2.3.7c-.2.7-.4 1.2-.7 1.6s-.8.8-1.3 1-1.1.3-1.9.3c-.9 0-1.7-.1-2.3-.4-.6-.2-1.1-.7-1.5-1.4S6 10.2 6 9.2c0-1.4.4-2.5 1.1-3.3.8-.8 1.8-1.1 3.2-1.1 1.1 0 1.9.2 2.6.7s1.1 1.1 1.4 2L12 8c-.1-.3-.2-.5-.3-.6-.1-.2-.3-.4-.5-.5s-.5-.2-.7-.2c-.8 0-1.2.3-1.5.8-.3.3-.4.9-.4 1.7 0 1 .1 1.6.4 2s.7.5 1.2.5.9-.1 1.2-.4.4-.8.6-1.3zm9.2-5.1h2.6V10c0 .5-.1 1-.2 1.4s-.4.8-.7 1.2c-.3.3-.7.6-1.1.7-.5.2-1.1.3-1.9.3-.4 0-.9 0-1.4-.1s-.9-.2-1.2-.3c-.3-.2-.6-.4-.9-.7-.3-.3-.5-.6-.6-1-.2-.5-.2-1-.2-1.5V4.9h2.6v5.2c0 .5.1.8.4 1.1.3.3.6.4 1.1.4.5 0 .8-.1 1.1-.4.3-.3.4-.6.4-1.1V4.9zm3.1 12.6H5.5c-.5 0-.9.4-.9.9s.4.9.9.9h18.8c.5 0 .9-.4.9-.9s-.4-.9-.9-.9zm0 3.3H5.5c-.5 0-.9.4-.9.9s.4.9.9.9h18.8c.5 0 .9-.4.9-.9s-.4-.9-.9-.9zm0 3.2H5.5c-.5 0-.9.4-.9.9s.4.9.9.9h18.8c.5 0 .9-.4.9-.9s-.4-.9-.9-.9z"
    />
  </svg>
);

export default Cud;
