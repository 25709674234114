import { IconComponent } from 'src/types/icon';

const Phone: IconComponent = (props) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    {...props}
    >
    <path d="M.655 10.18C.231 9.037-.076 7.867.017 6.624c.058-.765.346-1.418.906-1.954.61-.575 1.184-1.18 1.784-1.764.78-.77 1.76-.766 2.539 0 .482.472.96.955 1.438 1.437.463.463.93.926 1.393 1.394.814.824.82 1.784.005 2.603-.585.584-1.165 1.174-1.76 1.75-.155.15-.17.277-.087.467.39.936.955 1.77 1.589 2.549 1.277 1.57 2.72 2.963 4.44 4.04.37.23.775.4 1.16.61.2.107.331.072.492-.093.58-.6 1.174-1.19 1.769-1.78.78-.77 1.754-.774 2.534 0 .955.946 1.906 1.897 2.851 2.852.795.8.79 1.779-.01 2.583-.54.546-1.11 1.062-1.618 1.632-.74.829-1.676 1.102-2.738 1.043-1.55-.082-2.978-.599-4.357-1.267-3.061-1.486-5.673-3.548-7.861-6.15-1.618-1.93-2.954-4.026-3.831-6.394Zm23.344 1.746C24 5.35 18.65 0 12.074 0v2.271c5.322 0 9.654 4.333 9.654 9.655H24Zm-6.53 0h2.27c0-4.226-3.44-7.667-7.665-7.667v2.272A5.36 5.36 0 0 1 15.89 8.11a5.36 5.36 0 0 1 1.579 3.816Z"/>
  </svg>
  
);

export default Phone;
