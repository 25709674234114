import { IconComponent } from 'src/types/icon';

const Hamburger: IconComponent = (props) => (
  <svg
    viewBox="0 0 32 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill={props.fill}
      d="M29 8H3a2 2 0 0 1 0-4h26a2 2 0 0 1 0 4zm0 20H3a2 2 0 0 1 0-4h26a2 2 0 0 1 0 4zm0-10H3a2 2 0 0 1 0-4h26a2 2 0 0 1 0 4z"
    />
  </svg>
);

export default Hamburger;
