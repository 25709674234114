import { IconComponent } from 'src/types/icon';

const FormIcon: IconComponent = (props) => (
  <svg
    viewBox="0 0 24 32"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path d="M23.73 7.655 16.344.27a.93.93 0 0 0-.653-.27H2.77A2.772 2.772 0 0 0 0 2.77v25.968a2.772 2.772 0 0 0 2.77 2.77h18.46a2.772 2.772 0 0 0 2.77-2.77V8.308a.932.932 0 0 0-.27-.653Zm-7.115-4.503 4.233 4.233h-3.31a.924.924 0 0 1-.923-.923v-3.31Zm4.616 26.51H2.769a.924.924 0 0 1-.923-.924V2.77c0-.509.414-.923.923-.923h12v4.616a2.772 2.772 0 0 0 2.77 2.769h4.615v19.507a.924.924 0 0 1-.923.924Z"/>
    <path d="M17.538 14.892h-9.23a.923.923 0 0 0 0 1.846h9.23a.923.923 0 0 0 0-1.846Zm0 3.693h-9.23a.923.923 0 0 0 0 1.846h9.23a.923.923 0 0 0 0-1.846Zm0 3.692h-9.23a.923.923 0 0 0 0 1.846h9.23a.923.923 0 0 0 0-1.846Z"/>
    <circle cx="4.615" cy="15.815" r="1"/>
    <circle cx="4.615" cy="19.508" r="1"/>
    <circle cx="4.615" cy="23.2" r="1"/>
  </svg>
  
);

export default FormIcon;
