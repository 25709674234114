import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'src/store';

export type RedirectPath =
  | '/'
  | '/login'
  | '/merchant'
  | '/help-form'
  | '/reset-password'
  | '/merchant/account-connection'
  | '/merchant/aml/personal-data'
  | '/merchant/dashboard'
  | '/merchant/loan-summary'
  | '/merchant/account-connection/success'
  | '/merchant/borrowerData'
  | '/merchant/document-upload'
  | '/merchant/financial-document-upload'
  | '/user'
  | '/user/account-connection'
  | '/user/account-connection/success'
  | '/user/aml/personal-data'
  | '/user/aml/success'
  | '/fic-connect';
export type NotificationState = {
  context: string;
  status: 'error' | 'success';
  doubleCta: 'true' | 'false';
  ctaRedirectPath: RedirectPath;
  showToast?: boolean;
  toastContent?: string;
  ctaTextSecondary?: string;
  ctaRedirectPathSecondary?: RedirectPath;
};

export const initialNotificationState: NotificationState = {
  context: '',
  status: 'error',
  doubleCta: 'false',
  ctaRedirectPath: '/',
  showToast: false,
  toastContent: '',
  ctaTextSecondary: '',
  ctaRedirectPathSecondary: '/',
};

export const createNotificationSlice = (
  initialState: NotificationState = initialNotificationState
) =>
  createSlice({
    name: 'notification',
    initialState,
    reducers: {
      setNotification: (
        state: NotificationState,
        action: PayloadAction<NotificationState>
      ) => {
        state.context = action.payload.context;
        state.ctaRedirectPath = action.payload.ctaRedirectPath;
        state.status = action.payload.status;
        state.doubleCta = action.payload.doubleCta;
        state.ctaTextSecondary = action.payload.ctaTextSecondary
          ? action.payload.ctaTextSecondary
          : '';
        state.ctaRedirectPathSecondary = action.payload.ctaRedirectPathSecondary
          ? action.payload.ctaRedirectPathSecondary
          : '/';
      },
      resetNotification: (state: NotificationState) => {
        state.context = initialState.context;
        state.ctaRedirectPath = initialState.ctaRedirectPath;
        state.status = initialState.status;
        state.doubleCta = initialState.doubleCta;
        state.ctaTextSecondary = initialState.ctaTextSecondary;
        state.ctaRedirectPathSecondary = initialState.ctaRedirectPathSecondary;
      },
      setToast: (
        state: NotificationState,
        action: PayloadAction<{
          showToast: boolean;
          toastContent: string;
        }>
      ) => {
        state.showToast = action.payload.showToast;
        state.toastContent = action.payload.toastContent;
      },
    },
  });

export const notificationSlice = createNotificationSlice();

export const { actions } = notificationSlice;

export default notificationSlice.reducer;

export const notificationSelector = createSelector(
  (state: RootState) => state.notification,
  (state) => state
);

export const toastSelector = createSelector(
  (state: RootState) => state.notification,
  (state) => {
    return [state.showToast, state.toastContent];
  }
);
