import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from 'src/components/AppWrapper/components/Header';
import Loader from '../Loader';
import Footer from './components/Footer';
import Main from './components/Main';
import Paper from './components/Paper';

export type Props = {
  isLoading: boolean;
};

const AppWrapper: React.FC<Props> = ({ isLoading }) => {
  return (
    <>
      <div className="App">
        <Header />
        <Main>
          {isLoading ? (
            <Paper header="" subheader="">
              <Loader size={120} />
            </Paper>
          ) : (
            <Outlet />
          )}
        </Main>
        <Footer />
      </div>
    </>
  );
};

export default AppWrapper;
