import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import faicreditLogoBlue from 'src/components/AppWrapper/components/Header/components/partner-logo/faicredit_logo_blue.svg';
import faicreditLogo from 'src/components/AppWrapper/components/Header/components/partner-logo/faicredit_logo_white.svg';

import sellaLogo from 'src/components/AppWrapper/components/Header/components/partner-logo/sella-personal-credit.svg';
import useMerchant from 'src/features/login/hooks/useMerchant';
import styles from './LogoLink.module.scss';

export type LogoLinkProps = {
  linkClassName?: string;
  logoClassName?: string;
  color: 'white' | 'black' | 'gradient';
  placement: 'header' | 'footer';
  alt: string;
  isFicConnectPage?: boolean;
};

const LogoLink: React.FC<LogoLinkProps> = ({
  isFicConnectPage = false,
}: LogoLinkProps) => {
  const { isUser } = useMerchant();
  const { t } = useTranslation('navbar');

  return (
    <NavLink to="/" className={styles.link__wrapper}>
      <img
        className={classNames(styles.logo, [styles.faicreditLogo])}
        src={isFicConnectPage ? faicreditLogoBlue : faicreditLogo}
      />
      {isUser() ? (
        <>
          <p className={styles.powered}>{t('navbar.partnership.label')}</p>
          <img
            className={classNames(styles.logo, [styles.logoSella])}
            src={sellaLogo}
          />
        </>
      ) : null}
    </NavLink>
  );
};

export default LogoLink;
