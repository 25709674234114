import { IconComponent } from 'src/types/icon';
import styles from './Pulse.module.scss';

const Pulse: IconComponent = (props) => (
  <div {...props} className={styles.lds_ripple}>
    <div></div>
    <div></div>
  </div>
);

export default Pulse;
