import classNames from 'classnames';
import React from 'react';
import styles from './Loader.module.scss';
import FlexContainer from '../lib/FlexContainer';
import LoaderSVG, { LoaderSVGProps } from './LoaderSvg';

export type LoaderProps = LoaderSVGProps & {
  text?: string;
  textClassName?: string;
};

const Loader: React.FC<LoaderProps> = ({
  size,
  className,
  text,
  textClassName
}: LoaderProps) => {
  return (
    <FlexContainer flexDirection='column' alignItems='center' justifyContent='center' style={{ minHeight: 'calc(100vh - 164px)' }}>
      <LoaderSVG size={size} className={className} />
      {text && <p className={classNames(styles.paragraph, textClassName)}> {text} </p>}
    </FlexContainer>
  );
};

export default Loader;
