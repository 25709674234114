import React from 'react';
import { IconComponent } from 'src/types/icon';

const Exclamation: IconComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4 28" {...props}>
    <path fill={props.fill} fillRule="evenodd" d="M0 0h4v21H0zm0 24h4v4H0z" />
  </svg>
);

export default Exclamation;
