import { setUser } from '@sentry/react';
import classNames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from 'src/components/lib/Button';
import ContainerFluid from 'src/components/lib/ContainerFluid/ContainerFluid';
import FlexContainer from 'src/components/lib/FlexContainer';
import { FIC_TOKEN_KEY, TOKEN_KEY } from 'src/constants';
import useMerchant from 'src/features/login/hooks/useMerchant';
import Logout from 'src/features/logout/Logout';
import { setLoanTag } from 'src/utils/sentryUtils';
import LogoLink from '../LogoLink';
import styles from './Header.module.scss';
import CreateLoanLink from './components/CreateLoanLink';
import DashboardLink from './components/DashboardLink';
import HelpLink from './components/HelpLink';

const Header: React.FC = () => {
  const [showNavbar, setShowNavbar] = useState(false);
  const [isFicConnectPage, setIsFicConnectPage] = useState<boolean | undefined>(
    undefined
  );
  const { pathname } = useLocation();
  const { merchant } = useMerchant();
  const navigate = useNavigate();
  const { isUser } = useMerchant();

  const DashboardDestination = useCallback(() => {
    navigate('/merchant/dashboard');
    setShowNavbar(false);
  }, [navigate]);

  const CreateLoanDestination = useCallback(() => {
    navigate('/merchant/loan-simulation');
    setShowNavbar(false);
  }, [navigate]);

  const HelpLinkDestination = useCallback(() => {
    navigate('/help-form');
    setShowNavbar(false);
  }, [navigate]);

  const LogoutLinkDestination = useCallback(() => {
    sessionStorage.removeItem(TOKEN_KEY);
    const hasLoggedFromFic = localStorage.getItem(FIC_TOKEN_KEY);
    setUser(null);
    setLoanTag(null);
    if (!isUser()) {
      const route = hasLoggedFromFic ? '/fic-connect' : '/sso-connect';
      localStorage.removeItem(FIC_TOKEN_KEY);
      navigate(route);
    } else {
      window.location.reload();
    }
    setShowNavbar(false);
  }, [navigate]);

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };

  useEffect(() => {
    setIsFicConnectPage(pathname === '/fic-connect');
  }, [pathname]);

  return isFicConnectPage === undefined ? null : (
    <ContainerFluid
      className={classNames(styles.container, [
        `${isFicConnectPage && [styles.container__fic]}`,
      ])}
    >
      <header className={styles.container__header}>
        <LogoLink
          placement="header"
          color="white"
          alt="brand"
          isFicConnectPage={isFicConnectPage}
        />
        <Button
          className={styles.hamburger}
          onClick={handleShowNavbar}
          icon="Hamburger"
          iconPlacement="left"
        />
        <FlexContainer
          className={classNames(styles.navbar, [
            `${showNavbar && [styles.active]}`,
          ])}
        >
          <DashboardLink onClick={DashboardDestination} />
          <CreateLoanLink onClick={CreateLoanDestination} />
          <HelpLink onClick={HelpLinkDestination} />
          {merchant?.accessToken && <Logout onClick={LogoutLinkDestination} />}
        </FlexContainer>
      </header>
    </ContainerFluid>
  );
};

export default Header;
