import { IconComponent } from 'src/types/icon';

const DriveLicense: IconComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" {...props}>
    <path fill={props.fill} d="M27.2 4H2.8C1.2 4 0 5.3 0 6.8v16c0 1.5 1.3 2.8 2.8 2.8h24.5c1.5 0 2.8-1.3 2.8-2.8v-16C30 5.2 28.7 4 27.2 4zm1 18.8c0 .6-.4 1-1 1H2.8c-.6 0-1-.4-1-1v-16c0-.6.4-1 1-1h24.5c.6 0 1 .4 1 1l-.1 16z"/>
    <path fill={props.fill} d="M24.4 7.8H5.6c-.5 0-.9.4-.9.9s.4.9.9.9h18.8c.5 0 .9-.4.9-.9s-.4-.9-.9-.9zm-3.1 8.9-1.2-.5-1.1-1.8c-.3-.5-.8-.8-1.5-.8H13c-.5 0-1.1.3-1.3.7L10.3 16l-2.3.7c-.5.1-.9.7-.9 1.3v1c0 .8.5 1.3 1.3 1.3h.1c.3.9 1.2 1.5 2 1.2.5-.1 1.1-.7 1.2-1.2h5.1c.3.9 1.2 1.5 2 1.2.5-.1 1.1-.7 1.2-1.2h.5c.8 0 1.3-.7 1.3-1.3v-1c.1-.6-.1-1-.5-1.3zM10.2 21c-.5 0-1.1-.5-1.1-1.1s.5-1.1 1.1-1.1c.5 0 1.1.5 1.1 1.1.1.5-.4 1.1-1.1 1.1zm8.4 0c-.5 0-1.1-.5-1.1-1.1s.5-1.1 1.1-1.1c.5 0 1.1.5 1.1 1.1.1.5-.4 1.1-1.1 1.1zm2-1.4h-.3c-.1-.9-.9-1.6-1.9-1.5-.8.1-1.5.7-1.5 1.5h-5.1c-.1-.9-.9-1.6-1.9-1.5-.8.1-1.5.7-1.5 1.5h.1c-.4 0-.7-.3-.7-.7V18c0-.3.3-.7.5-.7l2.4-.7s.1 0 .1-.1l1.5-1.9c.3-.3.5-.4.8-.4h4.5c.4 0 .7.1.9.5l1.1 1.7.1.1 1.3.5c.3.1.4.4.4.7v.9c0 .8-.4 1-.8 1z"/>
    <path fill={props.fill} d="m21.4 16.4-1.2-.5-.9-1.6c-.3-.7-.9-.9-1.7-.9H13c-.7 0-1.3.3-1.6.8l-1.3 1.6-2.1.7c-.7.1-1.2.8-1.2 1.6v.9c0 .9.7 1.6 1.6 1.6.1.4.5.8.9 1.1.3.1.5.3.8.3.1 0 .4 0 .5-.1.5-.1 1.1-.7 1.3-1.2h4.7c.1.4.5.8.9 1.1.4.3.9.3 1.3.1.5-.1 1.1-.7 1.3-1.2h.4c1.1 0 1.6-.8 1.6-1.6V18c.1-.6-.2-1.2-.7-1.6zM8.2 17l2.4-.7 1.5-1.9c.3-.3.7-.5 1.1-.5h.1c-.4 0-.7.1-1.1.5l-1.5 1.9-2.5.7c-.4 0-.7.7-.7 1.1 0-.6.3-1 .7-1.1zm-.8 2c0 .5.4 1.1 1.1 1.1-.7-.1-1.1-.5-1.1-1.1zm3.5 1.3c-.1.1-.4.3-.7.3-.4 0-.7-.4-.7-.7 0-.4.4-.8.7-.8s.7.4.8.8c0 .3-.1.4-.1.4zm-.8-1.7h.3c-.2 0-.3-.2-.3 0zm1.6 1.4c0-.1 0-.1 0 0 0-.8-.7-1.3-1.3-1.5.3 0 .5.1.8.3.3.3.4.5.5.9v.3zm5.4.2-.1-.3h.1c0 .4.3.7.5.9-.2-.1-.3-.2-.5-.6zm.8.9c-.1 0-.1-.1-.1-.1l.1.1zm1.4-.8c-.1.1-.4.3-.7.3-.4 0-.7-.4-.7-.7 0-.4.4-.8.7-.8.3 0 .7.4.8.8 0 .3-.1.4-.1.4zm-.8-1.7h.3c-.2-.2-.3-.2-.3 0zm1.6 1.4c0-.1 0-.1 0 0 0-.8-.7-1.3-1.3-1.5.3 0 .5.1.8.3.3.3.4.5.5.9v.3zm.5-.6c-.1-.4-.4-.8-.7-1.1-.4-.4-.9-.5-1.5-.4-.9.1-1.6.7-1.7 1.5h-4.5c-.1-.4-.4-.8-.7-1.1-.4-.4-.9-.5-1.5-.4-.9.1-1.6.7-1.7 1.5-.1 0-.1-.1-.1-.3V18c0-.1.1-.3.1-.3l2.4-.7c.1 0 .3-.1.4-.3l1.3-1.7c.3-.3.4-.3.5-.3h4.5c.3 0 .4 0 .7.4l1.1 1.7.1.1 1.5.5c.1.1.3.3.3.4v.8c0 .5-.2.6-.5.8z"/>
  </svg>
  );

export default DriveLicense;
