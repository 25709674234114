import React from 'react';
import classNames from 'classnames';
import styles from './FlexContainer.module.scss';

export type FlexContainerProps = React.HTMLAttributes<HTMLDivElement> & {
  className?: string;
  flexDirection?: React.CSSProperties['flexDirection'];
  alignItems?: React.CSSProperties['alignItems'];
  justifyContent?: React.CSSProperties['justifyContent'];
  children?: React.ReactNode;
};

const FlexContainer = React.forwardRef<
  HTMLDivElement,
  FlexContainerProps
>(
  (
    {
      className,
      flexDirection,
      alignItems,
      justifyContent,
      children,
      ...props
    },
    ref
  ) => (
    <div
      className={classNames(styles.container, {
        [styles.column]: flexDirection && flexDirection === 'column',
        [styles.row]: flexDirection && flexDirection === 'row',
        [styles.rowReverse]: flexDirection && flexDirection === 'row-reverse',
        [styles.alignCenter]: alignItems && alignItems === 'center',
        [styles.alignStart]: alignItems && alignItems === 'flex-start',
        [styles.alignEnd]: alignItems && alignItems === 'flex-end',
        [styles.alignBaseline]: alignItems && alignItems === 'baseline',
        [styles.justifyCenter]: justifyContent && justifyContent === 'center',
        [styles.justifyStart]:
          justifyContent && justifyContent === 'flex-start',
        [styles.justifyEnd]: justifyContent && justifyContent === 'flex-end',
        [styles.justifyBaseline]:
          justifyContent && justifyContent === 'baseline',
        [styles.justifyBetween]:
          justifyContent && justifyContent === 'space-between',
        [styles.justifyAround]:
          justifyContent && justifyContent === 'space-around',
      }, className)}
      ref={ref}
      {...props}
    >
      {children}
    </div>
  )
);

export default FlexContainer;