import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'src/store';
import { getCurrencySymbol } from 'src/utils/currency';
import { LoanAmountRange, LoanSimulations } from '../api/response.types';
import {
  MAX_LOAN_AMOUNT,
  MIN_LOAN_AMOUNT,
  MIN_NUMBER_OF_INSTALMENTS,
} from '../constants';
import { LoanSelection, LoanSimulationState } from '../types';

export const createLoanDetailsSlice = (initialState: LoanSimulationState) =>
  createSlice({
    name: 'loanDetails',
    initialState,
    reducers: {
      setValueGood: (
        currentState: LoanSimulationState,
        action: PayloadAction<string>
      ) => {
        currentState.valueGood = action.payload
      },
      setSliderValues: (
        currentState: LoanSimulationState,
        action: PayloadAction<LoanAmountRange[]>
      ) => {
        const [firstValue] = action.payload;
        if (firstValue) {
          currentState.minAmount = firstValue.minAmount;
          currentState.maxAmount = firstValue.maxAmount;
        }
      },
      setAmountValue: (
        currentState: LoanSimulationState,
        action: PayloadAction<number>
      ) => {
          currentState.amountValue = action.payload;
      },
      setTotalCost: (
        currentState: LoanSimulationState,
        action: PayloadAction<number>
      ) => {
        currentState.totalCost = action.payload;
      },
      setProductType: (
        currentState: LoanSimulationState,
        action: PayloadAction<string>
      ) => {
        currentState.productType = action.payload;
      },
      setSelection: (
        currentState: LoanSimulationState,
        action: PayloadAction<LoanSelection>
      ) => {
        currentState.selectedPlanId = action.payload.planId;
        currentState.selectedProductId = action.payload.productId;
        currentState.selectedNumberOfInstalments =
          action.payload.numberOfInstalments;
        currentState.selectedInstalmentAmount = action.payload.instalmentAmount;
        currentState.apr = action.payload.apr;
        currentState.aprc = action.payload.aprc;
        currentState.loanPurposes = action.payload.loanPurposes;
      },
      setSimulations: (
        currentState: LoanSimulationState,
        action: PayloadAction<LoanSimulations>
      ) => {
        currentState.loanSimulations = action.payload;
      },
      setLoanApplicationId: (
        currentState: LoanSimulationState,
        action: PayloadAction<string>
      ) => {
        currentState.loanApplicationId = action.payload;
      },
      setApplicationCode: (
        currentState: LoanSimulationState,
        action: PayloadAction<string>
      ) => {
        currentState.applicationCode = action.payload;
      },
      setCurrency: (
        currentState: LoanSimulationState,
        action: PayloadAction<string>
      ) => {
        currentState.currency = action.payload;
      },
      setIsLoading: (
        currentState: LoanSimulationState,
        action: PayloadAction<boolean>
      ) => {
        currentState.isLoading = action.payload;
      },
      setIsError: (
        currentState: LoanSimulationState,
        action: PayloadAction<boolean>
      ) => {
        currentState.isError = action.payload;
      },
      setInputError: (
        currentState: LoanSimulationState,
        action: PayloadAction<string | undefined>
      ) => {
        currentState.inputError = action.payload;
      },
      resetLoanSimulation: () => {
        return initialState;
      },
    },
  });

export const initialState: LoanSimulationState = {
  minAmount: 1000,
  maxAmount: 30000,
  amountValue: parseFloat(
    `${(MAX_LOAN_AMOUNT - MIN_LOAN_AMOUNT) / 2 + MIN_LOAN_AMOUNT}`
  ),
  totalCost: 30000,
  productType: '',
  selectedProductId: '',
  currency: 'EUR',
  isLoading: true,
  isError: false,
  selectedNumberOfInstalments: MIN_NUMBER_OF_INSTALMENTS,
  selectedInstalmentAmount: '',
  apr: '',
  aprc: '',
  loanPurposes: [''],
  valueGood: ''
};

export const loanDetailsSlice = createLoanDetailsSlice(initialState);

export const { actions } = loanDetailsSlice;

export const valueGoodSelector = createSelector(
    (state: RootState) => state.loanSimulation,
    (state: LoanSimulationState) => state.valueGood
);

export const amountValueSelector = createSelector(
  (state: RootState) => state.loanSimulation,
  (state: LoanSimulationState) => state.amountValue
);

export const totalCostSelector = createSelector(
  (state: RootState) => state.loanSimulation,
  (state: LoanSimulationState) => state.totalCost
);

export const productTypeSelector = createSelector(
  (state: RootState) => state.loanSimulation,
  (state: LoanSimulationState) => state.productType
);

export const loanAmountRangeSelector = createSelector(
  (state: RootState) => state.loanSimulation,
  (state: LoanSimulationState): LoanAmountRange => ({
    minAmount: state.minAmount,
    maxAmount: state.maxAmount,
  })
);

export const inputErrorSelector = createSelector(
  (state: RootState) => state.loanSimulation,
  (state: LoanSimulationState) => state.inputError
);

export const loanSimulationsSelector = createSelector(
  (state: RootState) => state.loanSimulation,
  (state: LoanSimulationState) => state.loanSimulations?.loanSimulations
);

export const loanApplicationIdSelector = createSelector(
  (state: RootState) => state.loanSimulation,
  (state: LoanSimulationState) => state.loanApplicationId
);

export const isLoadingSelector = createSelector(
  (state: RootState) => state.loanSimulation,
  (state: LoanSimulationState) => state.isLoading
);

export const isErrorSelector = createSelector(
  (state: RootState) => state.loanSimulation,
  (state: LoanSimulationState) => state.isError
);

export const selectedPlanIdSelector = createSelector(
  (state: RootState) => state.loanSimulation,
  (state: LoanSimulationState) => state.selectedPlanId
);

export const selectedNumberOfInstalmentsSelector = createSelector(
  (state: RootState) => state.loanSimulation,
  (state: LoanSimulationState) => state.selectedNumberOfInstalments
);

export const selectedInstalmentAmountSelector = createSelector(
  (state: RootState) => state.loanSimulation,
  (state: LoanSimulationState) => state.selectedInstalmentAmount
);

export const currencySelector = createSelector(
  (state: RootState) => state.loanSimulation,
  (state: LoanSimulationState) => state.currency
);

export const currencySymbolSelector = createSelector(
  (state: RootState) => state.loanSimulation,
  (state: LoanSimulationState) => getCurrencySymbol(state.currency)
);

export const productIdSelector = createSelector(
  (state: RootState) => state.loanSimulation,
  (state: LoanSimulationState) => state.selectedProductId
);

export const applicationCodeSelector = createSelector(
  (state: RootState) => state.loanSimulation,
  (state: LoanSimulationState) => state.applicationCode
);

export default loanDetailsSlice.reducer;
