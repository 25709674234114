import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'src/store';
import { Guarantor } from '../../dashboard/api';
import { AddressData, BasicCustomerData, CustomerAmlData } from '../../types';

export type GuarantorDataState = Guarantor;

type Payload = Guarantor;

export type UpdatedAdditionalGuarantorDataState = BasicCustomerData & {
  homeAddress: AddressData;
  domicileAddress: AddressData | null;
  amlData: CustomerAmlData | null;
  id: string;
};

export const guarantorSliceFromState = (initialState: GuarantorDataState) =>
  createSlice({
    name: 'guarantor',
    initialState,
    reducers: {
      setGuarantor: (
        state: GuarantorDataState,
        action: PayloadAction<Payload>
      ) => {
        state.id = action.payload.id;
        state.firstName = action.payload.firstName;
        state.lastName = action.payload.lastName;
        state.email = action.payload.email;
        state.phoneNumber = action.payload.phoneNumber;
        state.fiscalCode = action.payload.fiscalCode;
        state.homeAddress = action.payload.homeAddress;
        state.domicileAddress = action.payload.domicileAddress;
        state.amlData = action.payload.amlData;
      },
      resetGuarantorData: () => {
        return initialState;
      },
    },
  });

export const guarantorSlice = guarantorSliceFromState({
  id: '',
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  fiscalCode: '',
  homeAddress: null,
  domicileAddress: null,
  amlData: null,
});

export const { actions } = guarantorSlice;

export default guarantorSlice.reducer;

export const guarantorStateSelector = createSelector(
  (state: RootState) => state.guarantor,
  (guarantorState: GuarantorDataState) => guarantorState
);
