export type LoginFormState = {
  email: string;
  password: string;
};

export type LoginRequestPayload = LoginFormState;

export enum MerchantRole {
    OPERATOR = "ROLE_OPERATOR",
    USER = "ROLE_USER",
    FREELANCER = "ROLE_FREELANCER",
    EMPLOYEE = "ROLE_EMPLOYEE",
    CUSTOMER = "ROLE_CUSTOMER",
}

export type Merchant = {
  accessToken: string;
  scope: MerchantRole[];
};

type LastStep =
  | 'USER_REGISTERED'
  | 'EVALUATION_POSITIVE'
  | 'USER_IDENTIFIED'
  | 'AML_COMPLETED';

export type Customer = {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  fiscalCode: string;
  phoneNumber: string;
  identificationType: 'MANUAL' | 'SPID';
  lastStep: LastStep;
};
