import { IconComponent } from 'src/types/icon';

const QontoFlower: IconComponent = (props) => (

  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...props}>
  <path fill={props.fill} d="M2.923 6.556A10.044 10.044 0 0 1 0 0a9.985 9.985 0 0 1 6.533 2.933A10.05 10.05 0 0 1 9.456 9.49a9.985 9.985 0 0 1-6.533-2.933Zm14.154 0a9.985 9.985 0 0 1-6.533 2.933 10.05 10.05 0 0 1 2.923-6.556A9.985 9.985 0 0 1 20 0a10.044 10.044 0 0 1-2.923 6.556Zm0 6.888A10.044 10.044 0 0 1 20 20a9.985 9.985 0 0 1-6.533-2.933 10.05 10.05 0 0 1-2.923-6.556 9.985 9.985 0 0 1 6.533 2.934Zm-14.154 0A10.07 10.07 0 0 1 9.49 10.51c-.133 2.47-1.208 4.804-2.957 6.557A9.985 9.985 0 0 1 0 20a10.044 10.044 0 0 1 2.923-6.556Z"/>
</svg>
);

export default QontoFlower;
