import { Trans, useTranslation } from 'react-i18next';
import { matchRoutes, useLocation } from 'react-router-dom';
import TextLink from 'src/components/lib/TextLink';
import styles from '../../Header.module.scss';

export type HelpLinkProps = {
  onClick: () => void;
};

const HelpLink: React.FC<HelpLinkProps> = ({ onClick }) => {
  const { t } = useTranslation('navbar');
  const location = useLocation();

  const isRoot = matchRoutes(
    [
      { path: '/help-form/*' },
      { path: '/sso-connect' },
      { path: '/fic-connect' },
      { path: '/fic-landing' },
      { path: '/fic-landing/prefill' },
    ],
    location
  );

  if (isRoot) {
    return null;
  }

  return (
    <Trans
      t={t}
      i18nKey="navbar.help.label"
      components={[
        <TextLink
          color="white"
          id="help-link"
          onClick={onClick}
          className={styles.textLink}
        />,
      ]}
    />
  );
};

export default HelpLink;
