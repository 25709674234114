import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CustomerAmlInfoData } from '../../types';

export type AmlDataState = Pick<CustomerAmlInfoData, 'citizenship' | 'housingType' | 'lastResidenceSince' | 'civilStatus' | 'isPep'>

export const amlDataSliceFromState = (initialState: AmlDataState) =>
  createSlice({
    name: 'aml',
    initialState,
    reducers: {
      setAmlData: (
        state: AmlDataState,
        action: PayloadAction<AmlDataState>
      ) => {
        state.citizenship = action.payload.citizenship;
        state.housingType = action.payload.housingType;
        state.lastResidenceSince = action.payload.lastResidenceSince;
        state.civilStatus = action.payload.civilStatus;
        state.isPep = action.payload.isPep;
      },
      resetAmlData: (state: AmlDataState) => {
        state.citizenship = initialState.citizenship;
        state.housingType = initialState.housingType;
        state.lastResidenceSince = initialState.lastResidenceSince;
        state.civilStatus = initialState.civilStatus;
        state.isPep = initialState.isPep;
      },
    }
  });

export const amlDataSlice = amlDataSliceFromState({
  citizenship: null,
  housingType: null,
  lastResidenceSince: null,
  civilStatus: null,
  isPep: null,
});

export const { actions } = amlDataSlice;

export default amlDataSlice.reducer;
