import { IconComponent } from 'src/types/icon';

const Question: IconComponent = (props) => (

  <svg xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    version="1.1"
    {...props}>
    <path d="M12 0C5.368 0 0 5.367 0 12c0 6.632 5.367 12 12 12 6.632 0 12-5.367 12-12 0-6.632-5.367-12-12-12Zm0 22.326C6.306 22.326 1.674
17.694 1.674 12 1.674 6.306 6.306 1.674 12 1.674c5.694 0 10.326 4.632 10.326 10.326 0 5.694-4.632 10.326-10.326 10.326Z"/>
    <path d="M11.645 15.184c-.663 0-1.2.553-1.2 1.216 0 .648.52 1.216 1.2 1.216.68 0 1.216-.568 1.216-1.216 0-.663-.553-1.216-1.216-1.216Zm.205-9.209c-2.132 0-3.112 1.264-3.112 2.117 0 .616.522.9.948.9.853 0 .506-1.216 2.117-1.216.79 0 1.421.347 1.421 1.074 0 .853-.884 1.343-1.405 1.785-.458.395-1.059 1.042-1.059 2.4 0 .822.222 1.059.87 1.059.773 0 .931-.347.931-.648 0-.821.016-1.295.885-1.974.426-.332 1.769-1.406 1.769-2.89 0-1.485-1.343-2.607-3.365-2.607Z" />
  </svg>
);

export default Question;
