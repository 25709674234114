import jwtDecode from 'jwt-decode';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { actions } from '../slice';
import { Customer, Merchant, MerchantRole } from '../types';
import * as Sentry from '@sentry/react'

export interface JwtPayload {
  iss: string;
  sub: string;
  exp: number;
  iat: number;
  scope: string;
  originator_id: string;
}

export type UseUserState = {
  setMerchant: (merchant: Merchant) => void;
  resetMerchant: () => void;
  setCustomer: (customer: Customer) => void;
  resetCustomer: () => void;
};

export const useLoginState = (): UseUserState => {
  const dispatch = useDispatch();

  const setMerchant = useCallback(
    (merchant: Merchant) => {
      const decoded = jwtDecode<JwtPayload>(merchant.accessToken);
      const scope = decoded?.scope?.split(' ').map(role => role as MerchantRole);
      const originatorId = decoded?.originator_id
      Sentry.setUser({ id: originatorId });
      dispatch(
        actions.setMerchant({
          ...decoded,
          accessToken: merchant.accessToken,
          scope
        })
      );
    },
    [dispatch]
  );

  const resetMerchant = useCallback(() => {
    dispatch(actions.resetMerchant());
  }, [dispatch]);

  const setCustomer = useCallback(
    (customer: Customer) => {
      dispatch(actions.setCustomer(customer));
    },
    [dispatch]
  );

  const resetCustomer = useCallback(() => {
    dispatch(actions.resetCustomer());
  }, [dispatch]);

  return {
    setMerchant,
    resetMerchant,
    setCustomer,
    resetCustomer,
  };
};
