import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React from 'react';
import { Provider } from 'react-redux';
import CookieModal from 'src/components/lib/CookieModal/CookieModal';
import Routing from './Routing';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import './i18n';
import store from './store';

const queryClient = new QueryClient({
  logger: {
    log: console.log,
    warn: console.warn,
    // eslint-disable-next-line
    error: () => {
      return;
    },
  },
  defaultOptions: {
    queries: {
      //other query settings
      refetchOnWindowFocus: false,
    },
  },
});

const App: React.FC = () => (
  <ErrorBoundary>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <Routing />
        <CookieModal />
      </Provider>
    </QueryClientProvider>
  </ErrorBoundary>
);

export default App;
