import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import navbarEn from 'src/components/AppWrapper/components/Header/translations/translations.en.json';
import navbarIt from 'src/components/AppWrapper/components/Header/translations/translations.it.json';
import statusBadgeEn from 'src/components/StatusBadge/translations/translations.en.json';
import statusBadgeIt from 'src/components/StatusBadge/translations/translations.it.json';
import stickyBarEn from 'src/components/lib/StickyBar/translations/translations.en.json';
import stickyBarIt from 'src/components/lib/StickyBar/translations/translations.it.json';
import toastEn from 'src/components/lib/Toast/translations/translations.en.json';
import toastIt from 'src/components/lib/Toast/translations/translations.it.json';
import helpFormEn from 'src/features/help-form/translations/en.json';
import helpFormIt from 'src/features/help-form/translations/it.json';
import loginEn from 'src/features/login/translations/translations.en.json';
import loginIt from 'src/features/login/translations/translations.it.json';
import agreementsEn from 'src/components/Agreements/translations/en.json';
import agreementsIt from 'src/components/Agreements/translations/it.json';
import merchantSubmitErrorEn from 'src/components/MessagePage/Messages/translations/messages.en.json';
import merchantSubmitErrorIt from 'src/components/MessagePage/Messages/translations/messages.it.json';
import merchantBorrowerAdditionalDataEn from 'src/features/merchant/aml/borrowerAdditionalData/translations/translations.en.json';
import merchantBorrowerAdditionalDataIt from 'src/features/merchant/aml/borrowerAdditionalData/translations/translations.it.json';
import merchantBorrowerDataEn from 'src/features/merchant/borrowerData/translations/translations.en.json';
import merchantBorrowerDataIt from 'src/features/merchant/borrowerData/translations/translations.it.json';
import merchantBorrowerOccupationDataEn from 'src/features/merchant/aml/borrowerOccupationData/translations/translations.en.json';
import merchantBorrowerOccupationDataIt from 'src/features/merchant/aml/borrowerOccupationData/translations/translations.it.json';
import dashboardEn from 'src/features/merchant/dashboard/translations/translations.en.json';
import dashboardIt from 'src/features/merchant/dashboard/translations/translations.it.json';
import merchantDocumentUploadEn from 'src/features/merchant/documentUpload/translations/translations.en.json';
import merchantDocumentUploadIt from 'src/features/merchant/documentUpload/translations/translations.it.json';
import financialDocumentUploadEn from 'src/features/merchant/financialDocumentUpload/translations/translations.en.json';
import financialDocumentUploadIt from 'src/features/merchant/financialDocumentUpload/translations/translations.it.json';
import merchantLoanSimulationEn from 'src/features/merchant/loanSimulation/translations/translations.en.json';
import merchantLoanSimulationIt from 'src/features/merchant/loanSimulation/translations/translations.it.json';
import loanRecapListEn from 'src/features/merchant/loanRecaps/translations/translations.en.json';
import loanRecapListIt from 'src/features/merchant/loanRecaps/translations/translations.it.json';
import recapEn from 'src/features/merchant/recap/translations/translations.en.json';
import recapIt from 'src/features/merchant/recap/translations/translations.it.json';
import passwordResetEn from 'src/features/passwordReset/translations/translations.en.json';
import passwordResetIt from 'src/features/passwordReset/translations/translations.it.json';
import privacyEn from 'src/features/privacy-policy/translations/en.json';
import privacyIt from 'src/features/privacy-policy/translations/it.json';
import setPasswordEn from 'src/features/setPassword/translations/translations.en.json';
import setPasswordIt from 'src/features/setPassword/translations/translations.it.json';
import ssoConnectEn from 'src/features/sso-connect/translations/en.json';
import ssoConnectIt from 'src/features/sso-connect/translations/it.json';
import commonEn from 'src/translations/common.en.json';
import commonIt from 'src/translations/common.it.json';
import ficConnectEn from 'src/features/fic-connect/translations/en.json';
import ficConnectIt from 'src/features/fic-connect/translations/it.json';
import connectPagesEn from 'src/components/ConnectPages/translations/en.json';
import connectPagesIt from 'src/components/ConnectPages/translations/it.json';
import ficLandingIt from 'src/features/fic/fic-landing/translations/it.json';
import ficLandingEn from 'src/features/fic/fic-landing/translations/en.json';
import ficPrefillIt from 'src/features/fic/prefill/translations/it.json';
import ficPrefillEn from 'src/features/fic/prefill/translations/en.json';

export const defaultNS = 'it';

// type Locale = "en" | "it";
// type Resources = {
//   [key in Locale]: {
//     translation: typeof en & typeof it;
//   };
// };
export const resources = {
  en: {
    login: loginEn,
    navbar: navbarEn,
    'privacy-policy': privacyEn,
    'set-password': setPasswordEn,
    'reset-password': passwordResetEn,
    dashboard: dashboardEn,
    loanRecapList: loanRecapListEn,
    recap: recapEn,
    agreements: agreementsEn,
    'merchant/loan-simulation': merchantLoanSimulationEn,
    'merchant/borrowerData': merchantBorrowerDataEn,
    'merchant/borrowerAdditionalData': merchantBorrowerAdditionalDataEn,
    'merchant/borrowerOccupationData': merchantBorrowerOccupationDataEn,
    'merchant/document-upload': merchantDocumentUploadEn,
    'merchant/financial-document-upload': financialDocumentUploadEn,
    'merchant/submit-error': merchantSubmitErrorEn,
    'help-form': helpFormEn,
    common: commonEn,
    stickyBar: stickyBarEn,
    statusBadge: statusBadgeEn,
    toast: toastEn,
    'sso-connect': ssoConnectEn,
    'fic-connect': ficConnectEn,
    'connect-pages': connectPagesEn,
    'fic-landing': ficLandingEn,
    'fic-prefill': ficPrefillEn,
  },
  it: {
    login: loginIt,
    navbar: navbarIt,
    'privacy-policy': privacyIt,
    'set-password': setPasswordIt,
    'reset-password': passwordResetIt,
    dashboard: dashboardIt,
    loanRecapList: loanRecapListIt,
    recap: recapIt,
    agreements: agreementsIt,
    'merchant/loan-simulation': merchantLoanSimulationIt,
    'merchant/document-upload': merchantDocumentUploadIt,
    'merchant/financial-document-upload': financialDocumentUploadIt,
    'merchant/borrowerData': merchantBorrowerDataIt,
    'merchant/borrowerAdditionalData': merchantBorrowerAdditionalDataIt,
    'merchant/borrowerOccupationData': merchantBorrowerOccupationDataIt,
    'merchant/submit-error': merchantSubmitErrorIt,
    'help-form': helpFormIt,
    statusBadge: statusBadgeIt,
    common: commonIt,
    stickyBar: stickyBarIt,
    toast: toastIt,
    'sso-connect': ssoConnectIt,
    'fic-connect': ficConnectIt,
    'connect-pages': connectPagesIt,
    'fic-landing': ficLandingIt,
    'fic-prefill': ficPrefillIt,
  },
};

export const initTranslations = (): void => {
  i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
      resources,
      defaultNS: defaultNS,
      fallbackLng: 'it',
      supportedLngs: ['en', 'it', 'it-it', 'it-IT'],
      keySeparator: '.',
      interpolation: {
        escapeValue: false,
      },
      detection: {
        order: [
          'querystring',
          'cookie',
          'navigator',
          'htmlTag',
          'path',
          'subdomain',
        ],
      },
      returnObjects: true,
    });
};

initTranslations();

export default i18n;
