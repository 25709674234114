import { IconComponent } from 'src/types/icon';

const HealthCard: IconComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" {...props}>
    <path
      fill={props.fill}
      d="M27.2 4H2.8C1.2 4 0 5.3 0 6.8v16c0 1.5 1.3 2.8 2.8 2.8h24.5c1.5 0 2.8-1.3 2.8-2.8v-16C30 5.2 28.7 4 27.2 4zm1 18.8c0 .6-.4 1-1 1H2.8c-.6 0-1-.4-1-1v-16c0-.6.4-1 1-1h24.5c.6 0 1 .4 1 1l-.1 16z"
    />
    <path
      fill={props.fill}
      d="M24.4 12.5h-9.6c-.5 0-.9.4-.9.9s.4.9.9.9h9.6c.5 0 .9-.4.9-.9s-.4-.9-.9-.9zm0-4.7H5.6c-.5 0-.9.4-.9.9s.4.9.9.9h18.8c.5 0 .9-.4.9-.9s-.4-.9-.9-.9zm0 8.4h-9.6c-.5 0-.9.4-.9.9s.4.9.9.9h9.6c.5 0 .9-.4.9-.9s-.4-.9-.9-.9zM22.5 20h-7.7c-.5 0-.9.4-.9.9s.4.9.9.9h7.7c.5 0 .9-.4.9-.9s-.4-.9-.9-.9zM7.8 16.1H5.6c-.5 0-.8-.4-.8-.8V13c0-.5.4-.8.8-.8h2.2c.5 0 .8.4.8.8v2.2c.1.5-.3.9-.8.9z"
    />
  </svg>
);

export default HealthCard;
