export const EMAIL_PATTERN_REGEX =
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
export const NAMES_REGEX = /^[A-Za-z'\s]*$/;
export const PHONE_NUMBER_REGEX = /^\+?[0-9]*$/;
export const IBAN_REGEX = /^[A-Z]{2}\d{2}[A-Z0-9]{1,30}$/;
export const HELP_ONBOARDING_FORM_ID = 'help-onboarding-form';
export const TOKEN_KEY = 'fairepay-token';
export const CUSTOMER_TOKEN_KEY = 'fairepay-customer-token';
export const FIC_TOKEN_KEY = 'faire-fic-token';
export const COMPANY_FISCAL_CODE_REGEX =
  /^([A-Z]{6}[\dLMNPQRSTUV]{2}[ABCDEHLMPRST]{1}[\dLMNPQRSTUV]{2}[A-Z]{1}[\dLMNPQRSTUV]{3}[A-Z]{1})$|([\d]{11})$/;
export const FISCAL_CODE_REGEX = /^[A-Z]{6}\d{2}[A-Z]\d{2}[A-Z]\d{3}[A-Z]$/i;
export const HEALTH_CARD_NUMBER_REGEX = /^\d{20}$/
export const HOUSE_NUMBER_REGEX = /^[1-9]\d*(?: ?(?:[a-zA-Z]|[/-] ?\d+[a-zA-Z]?))?$/;
export const LOCAL_APPLICATION_ID_TOKEN_KEY = 'loanApplicationId';
