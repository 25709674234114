import { IconComponent } from 'src/types/icon';

const RateCheck: IconComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...props}>
    <path
      fill={props.fill}
      d="M10 0C4.486 0 0 4.486 0 10s4.486 10 10 10 10-4.486 10-10S15.514 0 10 0Zm5.589 7.368L9.198 13.71a.983.983 0 0 1-1.378.025l-3.384-3.082a1.016 1.016 0 0 1-.075-1.404.992.992 0 0 1 1.403-.05l2.682 2.456L14.16 5.94a.999.999 0 0 1 1.429 0 .999.999 0 0 1 0 1.428Z"
    />
  </svg>
);

export default RateCheck;
