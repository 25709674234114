import { useSelector } from 'react-redux';
import { merchantStateSelector } from '../slice';
import { MerchantRole } from '../types';

export default function useMerchant() {
  const merchant = useSelector(merchantStateSelector);

  function isFreelance() {
    return merchant?.scope?.includes(MerchantRole.FREELANCER) ?? false;
  }

  function isEmployee() {
    return merchant?.scope?.includes(MerchantRole.EMPLOYEE) ?? false;
  }

  function isUser() {
    return (
      (merchant?.scope?.includes(MerchantRole.USER) ||
        merchant?.scope?.includes(MerchantRole.CUSTOMER)) ??
      false
    );
  }

  return { isFreelance, isEmployee, isUser, merchant };
}
