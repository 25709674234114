import { IconComponent } from 'src/types/icon';

const Success: IconComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 39" {...props}>
    <path
      fill={props.fill}
      d="M36.633 15.89c-.892 0-1.615.724-1.615 1.616v1.628a15.789 15.789 0 0 1-4.662 11.235 15.789 15.789 0 0 1-11.231 4.648h-.01c-8.763-.005-15.888-7.138-15.883-15.902A15.789 15.789 0 0 1 7.893 7.88a15.789 15.789 0 0 1 11.231-4.648h.01c2.24 0 4.413.464 6.46 1.375a1.616 1.616 0 1 0 1.314-2.951A19.004 19.004 0 0 0 19.136 0h-.012A19 19 0 0 0 5.61 5.594 19 19 0 0 0 0 19.114 19 19 0 0 0 5.594 32.64a19 19 0 0 0 13.52 5.61h.01a19 19 0 0 0 13.516-5.595 19 19 0 0 0 5.61-13.52v-1.629c0-.892-.724-1.616-1.617-1.616Z"
    />
    <path
      fill={props.fill}
      d="M39.527 2.234a1.616 1.616 0 0 0-2.285 0L19.125 20.351l-4.11-4.11a1.616 1.616 0 1 0-2.286 2.285l5.253 5.253c.316.315.73.473 1.143.473a1.61 1.61 0 0 0 1.142-.473l19.26-19.26a1.616 1.616 0 0 0 0-2.285Z"
    />
  </svg>
);

export default Success;
