import { Trans, useTranslation } from 'react-i18next';
import { matchRoutes, useLocation } from 'react-router-dom';
import TextLink from 'src/components/lib/TextLink';
import styles from '../../components/AppWrapper/components/Header/Header.module.scss';

export type DashboardLinkProps = {
  onClick: () => void;
};

const Logout: React.FC<DashboardLinkProps> = ({ onClick }) => {
  const { t } = useTranslation('navbar');
  const location = useLocation();

  const isRoot = matchRoutes(
    [
      { path: '/' },
      { path: '/login' },
      { path: '/sso-connect' },
      { path: '/fic-connect' },
      { path: '/fic-landing' },
      { path: '/fic-landing/prefill' },
    ],
    location
  );

  if (isRoot) {
    return null;
  }

  return (
    <Trans
      t={t}
      i18nKey="navbar.logout.label"
      components={[
        <TextLink
          color="white"
          id="logout-link"
          className={styles.textLink}
          onClick={onClick}
        />,
      ]}
    />
  );
};

export default Logout;
