import { IconComponent } from 'src/types/icon';

const Inps: IconComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" {...props}>
    <path
      fill={props.fill}
      d="M27.2.1H2.8C1.2.1 0 1.4 0 2.9v24.3C0 28.7 1.3 30 2.8 30h24.5c1.5 0 2.8-1.3 2.8-2.8V2.9C30 1.3 28.7.1 27.2.1zm1 27.1c0 .6-.4 1-1 1H2.8c-.6 0-1-.4-1-1V2.9c0-.6.4-1 1-1h24.5c.6 0 1 .4 1 1l-.1 24.3z"
    />
    <path
      fill={props.fill}
      d="M20.4 4.1h.2c.4 0 .6.3 1 .5.2.1.3-.2.5-.3h.4v4.8h-.2c-.2-1.1-.3-2.3-.6-3.4-.1-.4-.2-.8-.5-1-.2-.2-.6-.2-.8.1-.2.2-.2.5-.2.8 0 .6.2 1.3.4 1.9.5 1.8 1.5 3.4 1.9 5.3.2.9.2 1.9-.1 2.8-.2.5-.5.9-1 1.1-.5.1-.9 0-1.3-.3-.1-.1-.3-.3-.5-.2l-.5.5v-5.1h.3c.1 1 .2 2 .5 3 .1.5.3 1 .6 1.5.1.2.4.3.6.1.2-.2.3-.4.4-.7.1-.6 0-1.3-.1-1.9-.6-2-1.4-3.7-2-5.6-.3-1-.4-2.2.1-3.2.2-.3.5-.6.9-.7m-13.1.2h2.3v.2c-.3.1-.6.3-.6.6v10.4c0 .2 0 .3.1.5s.3.2.5.2v.2H7.3v-.2c.3 0 .6-.2.5-.5V5c0-.2-.3-.3-.5-.4v-.3m2.7 0h1.8c.4 2.1.7 4.1 1.1 6.2.1.6.1 1.2.3 1.8V5.1c0-.3-.3-.4-.5-.5v-.3h1.6v.3c-.3 0-.6.1-.6.4v11.5h-.9c-.6-3.4-1.2-6.7-1.7-10.1v9.3c0 .3.3.3.5.4v.3H10v-.3c.3 0 .6-.2.6-.5V5.3c0-.2 0-.4-.1-.6-.1-.1-.3-.1-.4-.2-.1 0-.1-.1-.1-.2m4.6.3v-.3h2.2c.4 0 .8.1 1 .4.4.4.6 1 .8 1.5.2.8.2 1.6.1 2.4-.1.8-.2 1.6-.8 2.2-.4.4-.9.5-1.5.5v4.1c0 .2 0 .5.2.6.1.1.3.1.4.1v.3h-2.4v-.3c.1 0 .3-.1.4-.1.1-.1.1-.3.1-.5V5c.1-.3-.2-.4-.5-.4m1.9 0V11c.2 0 .5 0 .7-.3.2-.4.2-.8.2-1.3V6.1c0-.4 0-.9-.2-1.3-.1-.2-.5-.2-.7-.2M12 18.2c2.2-.3 4.5 0 6.6.9.2-.3.3-.6.5-.9.3.7.6 1.5.9 2.2-.8.2-1.6.4-2.3.5.2-.3.3-.6.5-.9-1.2-.4-2.4-.7-3.6-.9-1.2-.1-2.5-.2-3.7 0-.3.1-.7.2-.8.5-.1.5.2 1 .5 1.3.6.7 1.4 1.1 2.1 1.5-.7.2-1.4.4-2.1.5.3.7.5 1.4.8 2.1-1-.5-2-1.1-2.8-1.8-.6-.6-1.3-1.2-1.3-2.1v-.2c.1-.7.6-1.2 1.2-1.6.9-.5 2.2-.9 3.5-1.1"
    />
    <path
      fill={props.fill}
      d="M19.7 19c.8.5 1.6 1.2 2.2 2 .4.5.7 1.1.7 1.7v.2c-.2 1.1-1.2 1.9-2.2 2.5-1.5.9-3.3 1-5 .8-.9-.1-1.8-.3-2.6-.5-.2.3-.4.7-.5 1h-.1v-.1c-.4-1.1-.9-2.2-1.3-3.4 1.2-.3 2.3-.6 3.5-.8-.2.4-.3.7-.5 1.1 1.5.6 3.1 1.1 4.7 1.2.7 0 1.4.1 2.1-.1.3-.1.6-.3.6-.6.1-.5 0-1-.3-1.4-.5-.8-1.4-1.3-2.2-1.7l1.5-.3c-.2-.6-.4-1.1-.6-1.6"
    />
  </svg>
);

export default Inps;
