import { IconComponent } from 'src/types/icon';

const Chevron: IconComponent = (props) => (
  <svg
    viewBox="0 0 14 8"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill={props.fill}
      d="m13.5808143.82467427-.6872206-.68717239C12.8020221.04573767 12.6965366 0 12.5772816 0c-.1189661 0-.2244034.04573767-.3159751.13750188L6.85901363 5.53950591 1.45696145.13764631c-.09161977-.0917642-.19705714-.13750188-.31616766-.13750188-.11915867 0-.22459604.04573768-.31616767.13750188L.13750188.82486686C.04573767.91643848 0 1.02187585 0 1.1410345c0 .11906238.0458821.22449975.13750188.31607138L6.54284597 7.8625944C6.6344176 7.95421418 6.73990311 8 6.85901363 8c.11911052 0 .22440346-.04578582.31592694-.13740559l6.40587373-6.40548852c.0915716-.09161978.1370685-.19705714.1370685-.31607138 0-.11915866-.0454969-.22459603-.1370685-.31636024Z"
    ></path>
  </svg>
);

export default Chevron;
