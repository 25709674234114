import {
  createSelector,
  createSlice,
  PayloadAction,
  Slice,
} from '@reduxjs/toolkit';
import { Agreements } from 'src/features/merchant/types';
import { RootState } from 'src/store';

export type AgreementsState = {
  agreements: Agreements;
};

export const initialState: AgreementsState = {
  agreements: {
    dataTreatment: false,
    marketing: false
  },
};

export const createUserDataSlice = (
  stateValue: AgreementsState = initialState
): Slice =>
  createSlice({
    name: 'agreements',
    initialState: stateValue,
    reducers: {
      setAgreementsData: (state, action: PayloadAction<Agreements>) => {
        state.agreements.dataTreatment = action.payload.dataTreatment;
        state.agreements.marketing = action.payload.marketing;
      },
    },
  });

export const userDataSlice = createUserDataSlice();

export const { actions } = userDataSlice;

export default userDataSlice.reducer;

export const agreementsSelector = createSelector(
  (state: RootState) => state.agreements,
  (state: AgreementsState) => state.agreements
);
