import { IconComponent } from 'src/types/icon';

const RatePercentage: IconComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...props}>
    <path
      fill={props.fill}
      d="M10 0C4.477 0 0 4.477 0 10s4.477 10 10 10 10-4.477 10-10C19.995 4.48 15.52.005 10 0ZM5.99 5.99a1.61 1.61 0 0 1 2.298 0 1.628 1.628 0 1 1-2.297 2.298 1.61 1.61 0 0 1 0-2.297Zm1.861 8.168A.93.93 0 1 1 6.4 12.995l5.74-7.153a.93.93 0 1 1 1.45 1.163l-5.739 7.153ZM14 14.01a1.61 1.61 0 0 1-2.298 0A1.628 1.628 0 0 1 14 11.712a1.61 1.61 0 0 1 .01 2.297H14Z"
    />
  </svg>
);

export default RatePercentage;
