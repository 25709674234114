import { useCallback, useState } from 'react';

export type UseModal = [isOpen: boolean, open: () => void, close: () => void];

export const useModal = (
  initialValue?: boolean,
  withoutOverflow?: boolean
): UseModal => {
  const [isOpen, setIsOpen] = useState<boolean>(initialValue || false);

  const open = useCallback(() => {
    setIsOpen(true);
    if (!withoutOverflow) document.body.style.overflow = 'hidden';
  }, [setIsOpen, withoutOverflow]);

  const close = useCallback(() => {
    setIsOpen(false);
    if (!withoutOverflow) document.body.style.overflow = 'unset';
  }, [setIsOpen, withoutOverflow]);

  return [isOpen, open, close];
};
