import React from 'react';
import { IconComponent } from 'src/types/icon';

const Info: IconComponent = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14">
    <g fill="#090316">
      <path d="M7 .66667C3.31539.66667.33333 3.64833.33333 7.33333.33333 11.01794 3.315 14 7 14c3.6846 0 6.66667-2.98167 6.66667-6.66667C13.66667 3.64873 10.685.66667 7 .66667Zm0 12.4031c-3.16307 0-5.73643-2.57336-5.73643-5.73644C1.26357 4.17026 3.83693 1.5969 7 1.5969c3.16307 0 5.73643 2.57336 5.73643 5.73643 0 3.16308-2.57336 5.73643-5.73643 5.73643Z" />
      <path d="M7 5.96898c-.25688 0-.4651.20826-.4651.4651v3.81397c0 .25687.20825.4651.4651.4651s.4651-.20825.4651-.4651V6.4341c0-.25687-.20823-.46513-.4651-.46513Zm0-2.01549c-.25688 0-.4651.20825-.4651.4651v.55815c0 .25688.20825.4651.4651.4651s.4651-.20825.4651-.4651V4.4186c0-.25685-.20823-.4651-.4651-.4651Z" />
    </g>
  </svg>
);

export default Info;
