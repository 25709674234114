import axios from 'src/axios';
import { Customer, LoginRequestPayload, Merchant } from '../types';

export const merchantLogin = (
  payload: LoginRequestPayload
): Promise<Merchant> => axios.post(`/login`, payload).then(({ data }) => data);

export const qontoLogin = (code: string): Promise<Merchant> =>
  axios.get(`/login/oauth2/qonto/token?code=${code}`).then(({ data }) => data);

export const merchantCheck = (): Promise<Merchant> =>
  axios.get(`/me`).then(({ data }) => data);

export const customerCheck = (): Promise<Customer> =>
  axios.get(`/customer/me`).then(({ data }) => data);
