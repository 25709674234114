import classNames from 'classnames';
import React from 'react';
import { Trans } from 'react-i18next';
import Loader from 'src/components/Loader';
import FlexContainer, {
  FlexContainerProps,
} from 'src/components/lib/FlexContainer';
import visualLogin from 'src/features/login/img/login-visual.svg';
import useIcon from 'src/hooks/useIcon';
import styles from './Paper.module.scss';

export type PaperStatus = 'error' | 'success' | 'default';

export type PaperProps = FlexContainerProps & {
  header?: React.ReactNode;
  subheader?: React.ReactNode;
  note?: React.ReactNode;
  caption?: React.ReactNode;
  status?: PaperStatus;
  loader?: boolean;
  icon?: string;
  children?: React.ReactNode;
  loginGrid?: boolean;
  subcaption?: React.ReactNode;
  dashboardGrid?: boolean;
};

const Paper: React.FC<PaperProps> = ({
  children,
  header,
  subheader,
  note,
  caption,
  loader,
  status = 'default',
  icon,
  loginGrid,
  dashboardGrid,
  subcaption,
  ...props
}) => {
  const Icon = useIcon(icon);

  return (
    <FlexContainer
      {...props}
      className={classNames(
        styles.container,
        {
          [styles.loginGrid]: loginGrid,
          [styles.dashboardGrid]: dashboardGrid,
        },
        props.className,
        styles[status],
      )}
      justifyContent="center"
      alignItems="center"
    >
      {loginGrid && (
        <FlexContainer className={styles.container__caption}>
          <FlexContainer flexDirection="column">
            <h1>{caption}</h1>
            <h2>{subcaption}</h2>
          </FlexContainer>
          <img className={styles.container__visual} src={visualLogin} alt="" />
        </FlexContainer>
      )}
      <FlexContainer
        flexDirection="column"
        className={styles.container__paperContent}
      >
        {Icon ? (
          <Icon
            className={classNames(styles[status], styles.icon)}
          />
        ) : null}
        <h2>{header}</h2>
        {subheader && (
          <Trans>
            <h3>{subheader}</h3>
          </Trans>
        )}
        {note && <p className={styles.note}>{note}</p>}
        {loader && <Loader className={styles.container__loader} size={120} />}
        {children}
      </FlexContainer>
    </FlexContainer>
  );
};

export default Paper;
