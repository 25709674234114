import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import ContainerFluid from 'src/components/lib/ContainerFluid/ContainerFluid';
import LogoLink from '../LogoLink';
import styles from './Footer.module.scss';

const Footer: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <ContainerFluid className={styles.container}>
        <footer className={styles.container__footer}>
          <LogoLink
            placement="footer"
            color="gradient"
            alt="faicredit"
            linkClassName={styles.container__logo}
            logoClassName={styles.container__logoLink}
          />
          <p className={styles.container__address}>
            {t('common:footer.disclaimer')}
          </p>
          <NavLink
            className={styles.container__privacy}
            to="/privacy-policy"
            target="_blank"
            rel="noreferrer"
          >
            {t('common:footer.privacy_policy')}
          </NavLink>
          <NavLink
            className={styles.container__cookies}
            to="/privacy-policy"
            target="_blank"
            rel="noreferrer"
          >
            {t('common:footer.cookie_policy')}
          </NavLink>
        </footer>
      </ContainerFluid>
    </>
  );
};

export default Footer;
