import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { LoanAmountRange, LoanSimulations } from '../api/response.types';
import { LoanSelection, LoanSimulationState } from '../types';
import { actions } from './index';

export type UseLoanSimulationState = {
  setAmountValue: (value: number) => void;
  setValueGood: (value: string) => void;
  setTotalCost: (value: number) => void;
  setProductType: (value: string) => void;
  setSliderValues: (value: LoanAmountRange[]) => void;
  setInputError: (value?: string) => void;
  setCurrency: (value: string) => void;
  setSimulations: (value: LoanSimulations) => void;
  setLoanApplicationId: (value: string) => void;
  setApplicationCode: (value: string) => void;
  setIsLoading: (value: boolean) => void;
  setIsError: (value: boolean) => void;
  setSelection: (value: LoanSelection) => void;
  resetLoanSimulation: () => void;
  loanDataState: LoanSimulationState;
};

export const useLoanSimulationState = (): UseLoanSimulationState => {
  const dispatch = useDispatch();
  
  const loanDataState = useSelector((state: RootState) => state.loanSimulation);

  const setValueGood = useCallback(
    (value: string) => {
        dispatch(actions.setValueGood(value));
    },
    [dispatch]
  );

  const setAmountValue = useCallback(
    (value: number) => {
      dispatch(actions.setAmountValue(value));
    },
    [dispatch]
  );

  const setTotalCost = useCallback(
    (value: number) => {
      dispatch(actions.setTotalCost(value));
    },
    [dispatch]
  );

  const setProductType = useCallback(
    (value: string) => {
      dispatch(actions.setProductType(value));
    },
    [dispatch]
  );

  const setSliderValues = useCallback(
    (value: LoanAmountRange[]) => {
      const sliderValues = value.map((item) => ({
        minAmount: item.minAmount,
        maxAmount: item.maxAmount,
      }));
      dispatch(actions.setSliderValues(sliderValues))
      dispatch(actions.setAmountValue(Number(sliderValues[0].minAmount) + 5000));
    },
    [dispatch]
  );

  const setInputError = useCallback(
    (value?: string) => {
      dispatch(actions.setInputError(value));
    },
    [dispatch]
  );

  const setSimulations = useCallback(
    (value: LoanSimulations) => {
      dispatch(actions.setSimulations(value));
    },
    [dispatch]
  );

  const setLoanApplicationId = useCallback(
    (value: string) => {
      dispatch(actions.setLoanApplicationId(value));
    },
    [dispatch]
  );

  const setApplicationCode = useCallback(
    (value: string) => {
      dispatch(actions.setApplicationCode(value));
    },
    [dispatch]
  );

  const setIsLoading = useCallback(
    (value: boolean) => {
      dispatch(actions.setIsLoading(value));
    },
    [dispatch]
  );

  const setIsError = useCallback(
    (value: boolean) => {
      dispatch(actions.setIsError(value));
    },
    [dispatch]
  );

  const setCurrency = useCallback(
    (value: string) => {
      dispatch(actions.setCurrency(value));
    },
    [dispatch]
  );

  const setSelection = useCallback(
    (value: LoanSelection) => {
      dispatch(actions.setSelection(value));
    },
    [dispatch]
  );

  const resetLoanSimulation = useCallback(() => {
    dispatch(actions.resetLoanSimulation());
  }, [dispatch]);

  return {
    setAmountValue,
    setValueGood,
    setTotalCost,
    setProductType,
    setSliderValues,
    setCurrency,
    setInputError,
    setSelection,
    setIsLoading,
    setIsError,
    setSimulations,
    setLoanApplicationId,
    setApplicationCode,
    resetLoanSimulation,
    loanDataState,
  };
};
