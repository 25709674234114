import { IconComponent } from 'src/types/icon';
import ArrowLeft from './arrowLeft/ArrowLeft';
import ArrowRight from './arrowRight/ArrowRight';
import BustaPaga from './bustaPaga/BustaPaga';
import Check from './check/Check';
import Chevron from './chevron/Chevron';
import CiDigital from './ci-digital/CiDigital';
import CiPaper from './ci-paper/CiPaper';
import Close from './close/Close';
import Cud from './cud/Cud';
import DriveLicense from './drive-license/DriveLicense';
import Exclamation from './exclamation/Exclamation';
import FormIcon from './form/FormIcon';
import Hamburger from './hamburger/Hamburger';
import HealthCard from './healthCard/HealthCard';
import Info from './info/Info';
import Inps from './inps/Inps';
import Interrogative from './interrogative/Interrogative';
import Pencil from './pencil/Pencil';
import Phone from './phone/Phone';
import Pulse from './pulse/Pulse';
import QontoFlower from './qontoFlower/QontoFlower';
import Question from './question/question';
import RateCheck from './rateCheck/RateCheck';
import RatePercentage from './ratePercentage/RatePercentage';
import RateStar from './rateStar/RateStar';
import Success from './success/Success';
import User from './user/User';

export type IconMap = Record<string, IconComponent>;

export const iconMap: IconMap = {
  Chevron,
  Close,
  Info,
  Interrogative,
  Check,
  Pulse,
  Exclamation,
  Pencil,
  User,
  ArrowRight,
  ArrowLeft,
  CiDigital,
  CiPaper,
  BustaPaga,
  Cud,
  Inps,
  HealthCard,
  Question,
  Phone,
  FormIcon,
  Hamburger,
  Success,
  DriveLicense,
  RateCheck,
  RatePercentage,
  RateStar,
  QontoFlower
};
