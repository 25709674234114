import { captureException, setTag } from '@sentry/react';
import { FC } from 'react';

const SENTRY_TAG_LOAN_APPLICATION_ID = 'loanApplicationId';

export const logAppError = <T>(error: string, source?: FC<T> | string) => {
  let msg = error;
  if (source) {
    const name = typeof source == 'string' ? source : source.name;
    msg = `${error}, source: ${name}`;
  }

  captureException(new Error(msg));
};

export const setLoanTag = (value: string | null) => {
  setTag(SENTRY_TAG_LOAN_APPLICATION_ID, value);
};
