import { IconComponent } from 'src/types/icon';

const ArrowRight: IconComponent = (props) => (
  <svg
    viewBox="0 0 20 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path fill={props.fill} d="m12 2-1.4 1.4L16.2 9H0v2h16.2l-5.6 5.6L12 18l8-8z"></path>
  </svg>
);

export default ArrowRight;
