import { IconComponent } from 'src/types/icon';

const CiDigital: IconComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" {...props}>
    <path
      fill={props.fill}
      d="M27.2 4H2.8C1.2 4 0 5.3 0 6.8v16c0 1.5 1.3 2.8 2.8 2.8h24.5c1.5 0 2.8-1.3 2.8-2.8v-16C30 5.2 28.7 4 27.2 4zm1 18.8c0 .6-.4 1-1 1H2.8c-.6 0-1-.4-1-1v-16c0-.6.4-1 1-1h24.5c.6 0 1 .4 1 1v16z"
    />
    <path
      fill={props.fill}
      d="M11.8 16.9c.5-.6.8-1.3.8-2.2 0-1.8-1.5-3.2-3.2-3.2S6.1 13 6.1 14.8c0 .8.3 1.6.8 2.2-1.3.8-2.2 2.3-2.2 4 0 .5.4.9.9.9h7.5c.5 0 .9-.4.9-.9 0-1.8-.9-3.3-2.2-4.1zm-2.4-3.6c.8 0 1.5.7 1.5 1.5s-.7 1.5-1.5 1.5-1.5-.7-1.5-1.5.6-1.5 1.5-1.5zM6.6 20c.4-1.2 1.5-2 2.7-2s2.4.8 2.7 2H6.6zm17.8-7.5h-6.6c-.5 0-.9.4-.9.9s.4.9.9.9h6.6c.5 0 .9-.4.9-.9s-.4-.9-.9-.9zm0-4.7H5.6c-.5 0-.9.4-.9.9s.4.9.9.9h18.8c.5 0 .9-.4.9-.9s-.4-.9-.9-.9zm0 8.4h-6.6c-.5 0-.9.4-.9.9s.4.9.9.9h6.6c.5 0 .9-.4.9-.9s-.4-.9-.9-.9zM22.5 20h-4.7c-.5 0-.9.4-.9.9s.4.9.9.9h4.7c.5 0 .9-.4.9-.9s-.4-.9-.9-.9z"
    />
  </svg>
);

export default CiDigital;
