import { IconComponent } from 'src/types/icon';

const User: IconComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props}>
    <g fill="none" transform="translate(.5 .5)">
      <circle cx="15.5" cy="15.5" r="15.5" stroke="#000" />
      <path
        fill={props.fill}
        d="M15.5408441 16.7865895c-1.2457454 0-2.2719537-.4186521-3.0786249-1.2661674-.8066712-.8424098-1.2100068-1.8890402-1.2100068-3.1347856 0-1.2508509.4033356-2.2872703 1.2049013-3.11436353.8015657-.82709326 1.8226685-1.24063989 3.0735194-1.24063989 1.2457454 0 2.2617427.41865215 3.0428863 1.27127298.7811436.84751534 1.1742682 1.88904014 1.1742682 3.13989104 0 1.2406399-.3931246 2.2770593-1.1742682 3.1041525-.7811436.8219878-1.7869299 1.2406399-3.0326753 1.2406399m-4.2886317 6.1317223c0-1.2508509.4033356-2.2872703 1.1997958-3.1143635.8015657-.8270933 1.8226685-1.2406399 3.0837304-1.2406399 1.2457454 0 2.2617427.4186521 3.0377808 1.2712729.7811436.8526209 1.1742682 1.8941457 1.1742682 3.1398911"
      />
    </g>
  </svg>
);

export default User;
