import TextLink from 'src/components/lib/TextLink';
import DiscreetModal from 'src/components/lib/DiscreetModal';
import React, { useCallback, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { COOKIES_CONSENT_STORAGE_KEY } from './constants';
import { useModal } from 'src/hooks/useModal';
import { Maybe } from 'true-myth';

const CookieModal: React.FC = () => {
  const [isOpen, open, close] = useModal(undefined, true);
  const { t } = useTranslation(['common']);
  const consentGiven = Maybe.of(
    localStorage.getItem(COOKIES_CONSENT_STORAGE_KEY)
  );

  const onClose = useCallback(() => {
    localStorage.setItem(COOKIES_CONSENT_STORAGE_KEY, 'true');
    close();
  }, [close]);

  const text = (
    <Trans
      t={t}
      i18nKey="common:cookie_banner"
      components={[
        <TextLink
          href={process.env.REACT_APP_COOKIES_POLICY_PDF}
          target="_blank"
          rel="noopener noreferrer"
        />,
      ]}
    />
  );

  useEffect(() => {
    consentGiven.match({
      Just: (val) => {
        if (val === 'false') open();
      },
      Nothing: () => open(),
    });
  }, [consentGiven, open]);

  return (
    <>
      {isOpen && (
        <DiscreetModal
          closeTitle={t('common:close')}
          onClose={onClose}
          id="cookies_modal"
          text={text}
      />
      )}
    </>
  );
};

export default CookieModal;
