import { IconComponent } from 'src/types/icon';

const RateStar: IconComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...props}>
    <path
      fill={props.fill}
      d="M10 0C4.476 0 0 4.476 0 10s4.476 10 10 10 10-4.476 10-10S15.524 0 10 0Zm6.276 8.867-3.116 2.937.951 3.41c.191.688-.147.95-.764.582L10 13.778 6.649 15.79c-.613.369-.973.107-.8-.591l.92-3.707L3.75 8.831c-.538-.475-.391-.858.325-.858h3.666L9.484 3.92c.285-.658.738-.658 1.014.004l1.702 4.05h3.716c.72.004.88.404.36.893Z"
    />
  </svg>
);

export default RateStar;
