import classNames from 'classnames';
import React from 'react';
import useIcon from 'src/hooks/useIcon';
import { IconMap } from '../Icons';
import styles from './Button.module.scss';

export type ButtonProps = React.ButtonHTMLAttributes<any> & {
  className?: string;
  id?: string;
  title?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  primary?: boolean;
  secondary?: boolean;
  tertiary?: boolean;
  tab?: boolean;
  accordion?: boolean;
  accordionBlack?: boolean;
  inverted?: boolean;
  icon?: keyof IconMap;
  iconPlacement?: 'left' | 'right';
  children?: React.ReactNode;
  disabled?: boolean;
};

const Button: React.FC<ButtonProps> = ({
  id,
  title,
  onClick,
  secondary,
  tertiary,
  tab,
  accordion,
  accordionBlack,
  primary,
  className,
  children,
  inverted,
  icon,
  iconPlacement,
  disabled,
  ...props
}) => {
  const Icon = useIcon(icon);

  const iconClassName = classNames(
    styles.container__icon,
    iconPlacement ? styles[iconPlacement] : ''
  );
  return (
    <button
      id={id}
      title={title}
      onClick={onClick}
      disabled={disabled}
      className={classNames(
        styles.container,
        {
          [styles.tab]: tab,
          [styles.tertiary]: tertiary,
          [styles.secondary]: secondary,
          [styles.accordion]: accordion,
          [styles.accordionBlack]: accordionBlack,
          [styles.primary]:
            !tab && !tertiary && !secondary && !accordion ? true : primary,
          [styles.inverted]: inverted,
        },
        className
      )}
      {...props}
    >
      {Icon && iconPlacement === 'left' ? (
        <Icon width={12} className={iconClassName} />
      ) : null}
      {children}
      {Icon && iconPlacement === 'right' ? (
        <Icon width={12} className={iconClassName} />
      ) : null}
    </button>
  );
};

export default Button;
