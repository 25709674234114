import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'src/store';
import {
  AddressData,
  BasicCustomerData,
  CustomerAmlData,
  CustomerData,
  IdentificationType,
  SignupBusinessResponse,
} from '../../types';

export type CustomerDataState = CustomerData & {
  id: string;
  identificationType?: IdentificationType;
};
export type UpdatedAdditionalCustomerDataState = BasicCustomerData & {
  homeAddress: AddressData;
  domicileAddress: AddressData | null;
  amlData: CustomerAmlData | null;
  id: string;
};

export type BusinessDataState = SignupBusinessResponse & {
  identificationType?: IdentificationType;
};

export const customerDataSliceFromState = (initialState: CustomerDataState) =>
  createSlice({
    name: 'customer',
    initialState,
    reducers: {
      setCustomerData: (
        state: CustomerDataState,
        action: PayloadAction<CustomerDataState>
      ) => {
        state.id = action.payload.id;
        state.firstName = action.payload.firstName;
        state.lastName = action.payload.lastName;
        state.email = action.payload.email;
        state.phoneNumber = action.payload.phoneNumber;
        state.fiscalCode = action.payload.fiscalCode;
        state.homeAddress = action.payload.homeAddress;
        state.domicileAddress = action.payload.domicileAddress;
        state.amlData = action.payload.amlData;
      },
      setIdentificationType: (
        state: CustomerDataState,
        action: PayloadAction<IdentificationType>
      ) => {
        state.identificationType = action.payload;
      },
      resetCustomerData: () => {
        return initialState;
      },
    },
  });

export const businessDataSliceFromState = (initialState: BusinessDataState) =>
  createSlice({
    name: 'business',
    initialState,
    reducers: {
      setBusinessData: (
        state: BusinessDataState,
        action: PayloadAction<BusinessDataState>
      ) => {
        state.companyType = action.payload.companyType;
        state.vatCode = action.payload.vatCode;
        state.companyName = action.payload.companyName;
        state.officeAddress = action.payload.officeAddress;
      },
      setIdentificationType: (
        state: BusinessDataState,
        action: PayloadAction<IdentificationType>
      ) => {
        state.identificationType = action.payload;
      },
      resetBusinessData: () => {
        return initialState;
      },
    },
  });

export const userDataSlice = customerDataSliceFromState({
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  fiscalCode: '',
  id: '',
  homeAddress: null,
  domicileAddress: null,
  amlData: null,
});

export const userDataBusinessSlice = businessDataSliceFromState({
  companyType: '',
  vatCode: '',
  legalNumber: '',
  companyName: '',
  officeAddress: '',
});

export const { actions } = userDataSlice;

export default userDataSlice.reducer;

export const businessDataActions = userDataBusinessSlice.actions;
export const businessDataReducer = userDataBusinessSlice.reducer;

export const customerIdSelector = createSelector(
  (state: RootState) => state.customerData,
  (state: CustomerDataState) => state.id
);

export const identificationTypeSelector = createSelector(
  (state: RootState) => state.customerData,
  (state: CustomerDataState) => state.identificationType
);
