import { useQuery } from '@tanstack/react-query';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import withSuspense from 'src/Routing/hocs/withSuspense';
import AppWrapper from 'src/components/AppWrapper';
import MessagePage from 'src/components/MessagePage';
import { merchantCheck } from 'src/features/login/api';
import { useLoginState } from 'src/features/login/hooks/useLoginState';
import { Merchant } from 'src/features/login/types';
import { useLoanSimulationState } from 'src/features/merchant/loanSimulation/slice/useLoanSimulationState';
import { notificationSelector } from 'src/features/notification/slice';
import { logAppError, setLoanTag } from 'src/utils/sentryUtils';
import PrivateRoute from './components/PrivateRoute';

const InvitationLink = withSuspense(
  React.lazy(() => import('src/features/invitation-link'))
);
const Login = withSuspense(React.lazy(() => import('src/features/login')));
const SetPassword = withSuspense(
  React.lazy(() => import('src/features/setPassword'))
);
const Error404 = withSuspense(
  React.lazy(() => import('src/features/error-404/Error404'))
);
const PrivacyPolicy = withSuspense(
  React.lazy(() => import('src/features/privacy-policy'))
);
const Connect = withSuspense(
  React.lazy(() => import('src/features/sso-connect'))
);
const FicConnect = withSuspense(
  React.lazy(() => import('src/features/fic-connect'))
);
const FicLanding = withSuspense(
  React.lazy(() => import('src/features/fic/fic-landing'))
);
const FicPrefill = withSuspense(
  React.lazy(() => import('src/features/fic/prefill'))
);
const PasswordReset = withSuspense(
  React.lazy(() => import('src/features/passwordReset'))
);
const Dashboard = withSuspense(
  React.lazy(() => import('src/features/merchant/dashboard'))
);
const LoanRecaps = withSuspense(
  React.lazy(() => import('src/features/merchant/loanRecaps'))
);
const Recap = withSuspense(
  React.lazy(() => import('src/features/merchant/recap'))
);
const MerchantLoanSimulation = withSuspense(
  React.lazy(() => import('src/features/merchant/loanSimulation'))
);
const MerchantBorrowerData = withSuspense(
  React.lazy(() => import('src/features/merchant/borrowerData'))
);
const MerchantBorrowerAdditionalData = withSuspense(
  React.lazy(() => import('src/features/merchant/aml/borrowerAdditionalData'))
);
const MerchantBorrowerOccupationData = withSuspense(
  React.lazy(() => import('src/features/merchant/aml/borrowerOccupationData'))
);
const MerchantDocumentUpload = withSuspense(
  React.lazy(() => import('src/features/merchant/documentUpload'))
);
const FinancialDocumentUpload = withSuspense(
  React.lazy(() => import('src/features/merchant/financialDocumentUpload'))
);
const HelpForm = withSuspense(
  React.lazy(() => import('src/features/help-form'))
);

const ScrollToTop: React.FC = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
};

const Routing = () => {
  const { setMerchant, resetMerchant } = useLoginState();
  const {
    ctaRedirectPath,
    status,
    doubleCta,
    ctaRedirectPathSecondary,
    ctaTextSecondary,
  } = useSelector(notificationSelector);
  const { isFetching: isMerchantFetching, isLoading: isMerchantLoading } =
    useQuery(['/me'], merchantCheck, {
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: (merchant: Merchant) => {
        setMerchant(merchant);
      },
      onError: () => {
        resetMerchant();
        logAppError('Error when checking merchant', Routing);
      },
    });

  const {
    loanDataState: { loanApplicationId },
  } = useLoanSimulationState();

  useEffect(() => {
    if (loanApplicationId) {
      setLoanTag(loanApplicationId);
    }
  }, [loanApplicationId]);

  const isAwaiting = isMerchantFetching || isMerchantLoading;

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<AppWrapper isLoading={isAwaiting} />}>
          <Route
            path={status === 'error' ? 'error' : 'success'}
            element={
              doubleCta === 'false' ? (
                <MessagePage
                  ctaRedirectPath={ctaRedirectPath}
                  status={status}
                  doubleCta={'false'}
                />
              ) : (
                <MessagePage
                  ctaRedirectPath={ctaRedirectPath}
                  status={status}
                  doubleCta={'true'}
                  ctaRedirectPathSecondary={
                    ctaRedirectPathSecondary ? ctaRedirectPathSecondary : '/'
                  }
                  ctaTextSecondary={ctaTextSecondary ?? ''}
                />
              )
            }
          />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="*" element={<Error404 />} />
          <Route index element={<Login />} />
          <Route path="reset-password">
            <Route index element={<PasswordReset />} />
          </Route>
          <Route path="login" element={<Login />} />
          <Route path="login/prospect" element={<InvitationLink />} />
          <Route path="set-password">
            <Route index element={<SetPassword />} />
          </Route>
          <Route path="sso-connect" element={<Connect />} />
          <Route path="fic-connect" element={<FicConnect />} />
          <Route path="fic-landing">
            <Route index element={<FicLanding />} />
            <Route path="prefill" element={<FicPrefill />} />
          </Route>
          <Route path="merchant" element={<PrivateRoute />}>
            <Route index element={<></>} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="loan-recaps" element={<LoanRecaps />} />
            <Route path="recap/:id" element={<Recap />} />
            <Route
              path="loan-simulation"
              element={<MerchantLoanSimulation />}
            />
            <Route path="borrowerData">
              <Route index element={<MerchantBorrowerData />} />
            </Route>
            <Route path="borrowerAdditionalData">
              <Route index element={<MerchantBorrowerAdditionalData />} />
            </Route>
            <Route path="borrowerOccupationData">
              <Route index element={<MerchantBorrowerOccupationData />} />
            </Route>
            <Route path="document-upload">
              <Route index element={<MerchantDocumentUpload />} />
            </Route>
            <Route path="financial-document-upload">
              <Route index element={<FinancialDocumentUpload />} />
            </Route>
          </Route>
          <Route
            path="guarantor/:idGuarantorLoanApplication"
            element={<PrivateRoute />}
          >
            <Route index element={<></>} />
            <Route path="borrowerData">
              <Route index element={<MerchantBorrowerData />} />
            </Route>
            <Route path="borrowerAdditionalData">
              <Route index element={<MerchantBorrowerAdditionalData />} />
            </Route>
            <Route path="borrowerOccupationData">
              <Route index element={<MerchantBorrowerOccupationData />} />
            </Route>
            <Route path="document-upload">
              <Route index element={<MerchantDocumentUpload />} />
            </Route>
            <Route path="financial-document-upload">
              <Route index element={<FinancialDocumentUpload />} />
            </Route>
          </Route>
          <Route path="help-form">
            <Route index element={<HelpForm />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Routing;
