import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'src/store';

type Company = {
  id: string;
  name: string;
  legalForm?: string;
  email: string;
  vatNumber?: string;
  fiscalCode?: string;
  address?: string;
  phoneNumber?: string;
  iban?: string;
};

export type CompanyState = {
  company: Company;
  companyId: string;
  accessToken: string;
  role: string;
  modifiable: boolean;
};

type Payload = {
  company: Company;
  id: string;
  accessToken?: string;
  role: string;
  modifiable?: boolean
};

export const companySliceFromState = (initialState: CompanyState) =>
  createSlice({
    name: 'fic company',
    initialState,
    reducers: {
      setCompany: (
        state: CompanyState,
        action: PayloadAction<Payload>
      ) => {
        state.role = action.payload.role;
        state.companyId = action.payload.id;
        state.company.id = action.payload.company.id;
        state.company.name = action.payload.company.name;
        state.company.email = action.payload.company.email;
        state.company.legalForm = action.payload?.company.legalForm ?? '';
        state.company.vatNumber = action.payload?.company.vatNumber ?? '';
        state.company.fiscalCode = action.payload?.company.fiscalCode ?? '';
        state.company.address = action.payload?.company.address ?? '';
        state.company.phoneNumber = action.payload?.company.phoneNumber ?? '';
        state.company.iban = action.payload?.company.iban ?? '';
        state.modifiable = action.payload?.modifiable ?? false;
      },
      setAccessToken: (state: CompanyState, action: PayloadAction<string>) => {
        state.accessToken = action.payload;
      },
    },
  });

export const compaySlice = companySliceFromState({
  company: { id: '', name: '', email: '' },
  companyId: '',
  accessToken: '',
  role: '',
  modifiable: false
});

export const { actions } = compaySlice;

export default compaySlice.reducer;

export const companyStateSelector = createSelector(
  (state: RootState) => state.company,
  (companyState: CompanyState) => companyState
);
